import {ToastsContainer, ToastsContainerPosition, ToastsStore} from 'react-toasts'
import {GoogleOAuthProvider} from "@react-oauth/google";
import React from 'react';
import logo from './logo.svg';
import './App.css';


import Header from "./components/Header";

function App() {
  return (
    <GoogleOAuthProvider clientId="521335006932-7p7d097e7urevemv8v04djf67jj75atk.apps.googleusercontent.com">
        <ToastsContainer position={ToastsContainerPosition.BOTTOM_RIGHT} store={ToastsStore}/>
        <Header />
    </GoogleOAuthProvider>

  );
}

export default App;
