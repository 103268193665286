import "./ArchivedOrders.css"

import { useEffect, useState } from "react";
import { ToastsStore } from 'react-toasts'

import { Orders, Email } from '../lib/ajax'
import { createOrders } from "./Orders";
import { ProductService, CartService } from "../lib/utils";

import { PlacedOrder } from "../models/PlacedOrder";

function CLAMP(min: number, max: number, n: number) {
	return Math.min(max, Math.max(min, n));
}

function ArchivedOrdersPage() {
	const itemsPerPage = 10;

	const [archivedOrdersHTML, setArchivedOrdersHTML] = useState<JSX.Element[]>([<a>Loading...</a>]);
	const [archivedOrders, setArchivedOrders] = useState<PlacedOrder[]>([])
	const [page, setPage] = useState(0);
	const [pages, setPages] = useState(1);

	useEffect(() => {
		setArchivedOrdersHTML(createOrders(archivedOrders.slice(page*itemsPerPage, (page+1)*itemsPerPage), "archive"));
	}, [page])
	
	useEffect(() => {
		const init = async() => {
			await ProductService.setProductList();
			let response;
			try {
				response = await Orders.getAll();
			} catch {
				ToastsStore.error("403: Forbidden");
			}

			let allArchivedOrders = response["response" as keyof JSON]["archived" as keyof JSON];
            let newArchivedOrders: PlacedOrder[] = [];

            for(let i in allArchivedOrders) {
                allArchivedOrders[i].id = i;
                newArchivedOrders.push(allArchivedOrders[i] as PlacedOrder);
            }
			newArchivedOrders = newArchivedOrders.reverse();

			setArchivedOrders(newArchivedOrders)
			
			setPages(Math.floor(newArchivedOrders.length/itemsPerPage));
			setArchivedOrdersHTML(createOrders(newArchivedOrders.slice(page*itemsPerPage, (page+1)*itemsPerPage), "archive"));
		}

		init();
	}, [])

	return <div>
		<br />	
		<h2 className="orderElementDiv" >Archived Orders</h2>  
		<button className={`pagebtn ${page === 0     ? "inactive" : "active"}`} onClick={(e) => setPage(CLAMP(0, pages, page-1))}>{"<"}</button>
		<button className={`pagebtn ${page === pages ? "inactive" : "active"}`} onClick={(e) => setPage(CLAMP(0, pages, page+1))}>{">"}</button> <br />
		<div className="orderElementDiv"> 
			{archivedOrdersHTML} 
		</div>
		<div className="pageNumberDisplay">
			Page {page+1}: {(page*itemsPerPage)+1}-{Math.min(archivedOrders.length, (page+1)*itemsPerPage)}
		</div>
	</div>
}

export default ArchivedOrdersPage;