import './Header.css';

import {useEffect, useState} from 'react';
import {Tab, TabList, TabPanel, Tabs} from 'react-tabs';
import {GoogleLogin} from "@react-oauth/google";
import Cookies from "universal-cookie";
import { ToastsStore } from 'react-toasts';

import { Auth } from "../lib/ajax";
import {ProductService} from "../lib/utils";
import {Credential} from '../models/Credential';

import Orders from "./Orders";
import ArchivedOrders from "./ArchivedOrders";
import Products from "./Products";
import CreateProduct from "./CreateProduct";
import Users from "./Users";

function Header() {

    const [tabIndex, setTabIndex] = useState(0);
    const [tabElements, setTabElements] = useState<JSX.Element[]>([<div>Log In</div>, <div>Log In</div>, <div>Log In</div>, <div>Log In</div>]);

    const loadTab = (index: number) => {
        setTabIndex(index);
    }

    const updateTabs = async() => { //update the tab content if the logged in user is an admin
		let user;
        try {
			user = await Auth.getUser();
			ToastsStore.success("Logged In");
		} catch {
			ToastsStore.error("Login to beepositiveapiary.com Failed");
			return;
		}
        
		if(user["response"]["permissions"] === 'admin') {
            setTabElements([
                <Orders />,
				<ArchivedOrders />,
                <Products />,
                <CreateProduct />,
				<Users />
            ]);
        } else {
            setTabElements([
                <div>403: Forbidden</div>,
                <div>403: Forbidden</div>,
                <div>403: Forbidden</div>,
                <div>403: Forbidden</div>,
                <div>403: Forbidden</div>
            ]);
            ToastsStore.error("403: Forbidden");
        }
    }

    const googleSignIn = () => {
        return <div style={{position: 'absolute', top: '10px', left: '10px'}}>
            <GoogleLogin
                onSuccess={credentialResponse => {
                    const cookies = new Cookies();
                    cookies.set("auth", (credentialResponse as Credential).credential, { path: '/' , sameSite: false, domain: process.env.REACT_APP_COOKIE_DOMAIN});
                    
                    updateTabs();
                }}
                onError={() => {
                    ToastsStore.error("Login google.com Failed");
                }}
                useOneTap
                auto_select
            />
        </div>
    }

    useEffect(() => {
        document.title = "Bee Positive Apiary";
        const init = async () => {
            await ProductService.setProductList();
            updateTabs();
        }
        init();
    }, []);

    return (
        <div>
            <div className='header'>
                <h1>Bee Positive Apiary</h1>
                <Tabs selectedIndex={tabIndex} onSelect={(index) => loadTab(index)}>
                    <TabList>
                        <Tab>Orders</Tab>
                        <Tab>Archived Orders</Tab>
                        <Tab>Products</Tab>
                        <Tab>Create Product</Tab>
                        <Tab>Accounts</Tab>
                    </TabList>


                    <TabPanel>{tabElements[0]}</TabPanel>
                    <TabPanel>{tabElements[1]}</TabPanel>
                    <TabPanel>{tabElements[2]}</TabPanel>
                    <TabPanel>{tabElements[3]}</TabPanel>
                    <TabPanel>{tabElements[4]}</TabPanel>
                </Tabs>
            </div>

            {googleSignIn()}
        </div>
    );

}

export default Header;