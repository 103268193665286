import "./Products.css"
import { useEffect, useState } from "react";
import { ToastsStore } from "react-toasts";
import { AxiosError } from "axios";

import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

import { ProductService, toastAxiosError } from "../lib/utils";
import { CDN, Database } from "../lib/ajax.js"

import { Product } from "../models/Product";

function ProductDropdown({products, defaultValue, onChange}: {products: Product[], defaultValue: string, onChange: (product: Product) => void}) {
    let dropdownHTML: JSX.Element[] = [];
    products.map((p, i) => (dropdownHTML[i] = <option key={p.id} value={p.id}>{p.name}</option>));
    return (
		<select onChange={(event) => onChange(ProductService.getProduct(event.target.value))} defaultValue={defaultValue}>
			{dropdownHTML}
		</select>
    );
}

function SubproductSelector(
	{defaultProduct, defaultPrice, products, onChange, onDelete, onNewImage}: 
	{defaultProduct: string, defaultPrice: number, products: Product[], 
		onChange: (oldID: string, newID: string, price: number) => void, onDelete: (ID: string) => void, 
		onNewImage: (ID: string, image: any) => void}) {
	
	const [ID, setID] = useState<string>(defaultProduct);
	const [price, setPrice] = useState<number>(defaultPrice);
	
	return (
	<div className="subproductSelectorDiv">
		<ProductDropdown products={products} defaultValue={defaultProduct} onChange={(p) => {
			onChange(ID, p.id, price);
			setID(p.id);
		}} />

		$<input className="priceInputBox" type="number" size={3} defaultValue={defaultPrice} onChange={(e) => {
			onChange(ID, ID, parseFloat(e.target.value));
			setPrice(parseFloat(e.target.value));
		}} />

		<button onClick={(e) => {onDelete(ID)}}>🗑</button>
		
		{/* <input className="fileUploadBox" type="file" onChange={(event) => {
			if(!event.target.files) {
				return;
			}
			onNewImage(ID, event.target.files[0])
		}}/> */}
	</div>);
}

function uploadImage(path: string, image: unknown) {
	const upload = async () => {
		try {
			await CDN.uploadImage(image, path);
			ToastsStore.success("Image Uploaded");
		} catch (e) {
			toastAxiosError(e as AxiosError);
		}
	}

	confirmAlert({
		title: 'Confirm Upload',
		message: `Are you sure you want to upload the image to ${path}`,
		buttons: [
		  {
			label: 'Yes',
			onClick: () => upload()
		  },
		  {
			label: 'No',
			onClick: () => ToastsStore.error("Upload Cancelled")
		  }
		]
	  });

}


function ProductElement({product, products}: {product: Product, products: Product[]}) {
	
	const [def_props, setDef_props] = useState(product); //default variables used to represent the state on the database, meant to disable the save button when the states match the database
	const [def_relations, setDef_relations] = useState(product.relations);

	const [props, setProps] = useState(product);
	const [relations, setRelations] = useState(JSON.parse(JSON.stringify(product.relations)));
	
	const [subproductHTML, setSubproductHTML] = useState<JSX.Element[]>([]);
	
	const [isChanged, setIsChanged] = useState<boolean>(false);
	
	useEffect(() => {
		setIsChanged(true);

		//turn off changed flag if the states match the db
	
		if(JSON.stringify(props) === JSON.stringify(def_props) && JSON.stringify(relations) == JSON.stringify(def_relations)) {
			setIsChanged(false);
		}

	}, [props, relations]);

	useEffect(() => {
		let html: JSX.Element[] = [];

		for(let i in relations) {
			html.push(
				<div key={i}>
					<SubproductSelector defaultProduct={i} defaultPrice={relations[i].price} products={products}
					onChange={(op, np, price) => {
						let newSubProducts = structuredClone(relations);
						newSubProducts[np] = {...newSubProducts[op], "price": price}; //carry over everything and update price
						if(op != np) { //if the subproduct changed, delete the old one
							delete newSubProducts[op];
						}
						setRelations(newSubProducts)
					}} 
					onDelete={(id) => {
						let newSubProducts = structuredClone(relations);
						delete newSubProducts[id];
						setRelations(newSubProducts);
					}} 
					onNewImage={(p, image) => {uploadImage(`relations/${props.id}/${p}`, image)}} />
					<br />
				</div>)
		}
		setSubproductHTML(html);
	}, [relations]);

	function saveProduct() {
		const save = async() => {
			try {
				await Database.setProduct(props.id, {
					"name": props.name,
					"description": props.description,
					"location": props.location,
					"stock": props.stock,
					"position": props.position,
					"relations": relations
				});
				setIsChanged(false);

				setDef_props(props);
				setDef_relations(relations);

				ToastsStore.success("Product Updated");
			} catch (e) {
				toastAxiosError(e as AxiosError);
			}
		}
		save();
	}

	function deleteItem() {
		const del = async() => {
			try {
				await Database.deleteProduct(product.id);
				ToastsStore.success("Product Deleted");
			} catch (e) {
				toastAxiosError(e as AxiosError);
			}
		}

		confirmAlert({
			"title": "Delete Product",
			"message": "Are you sure you want to delete this product?",
			"buttons": [
				{
					"label": "Yes",
					"onClick": () => {del()}
				},
				{
					"label": "No",
					"onClick": () => {}
				}
			]
		})
	}

	return (
		<div key={product.id} className="productContainer">
        
        <b>{product.name}{isChanged ? "*" : " "}</b>
        
        <button className={`top-right btn ${isChanged ? "active" : "inactive"}`} onClick={async() => {saveProduct()}} disabled={!isChanged}>Save</button>
        <button className={`bot-right btn delete`} onClick={async() => {deleteItem()}} >Delete</button>

        <br />
        <table>
        <tbody>
        <tr>
            <td>
                <b>Properties</b> <br />
                ID:       <br />
                Name:     <br />
                Location: <br />
                Stock:    <br />
                Desc:     <br />
				Position: <br />
				Image:    <br />
            </td>
            <td className="inputDivider">
                <br />
                <label>{product.id}                                                                                                               </label><br />
                <input defaultValue={product.name}         onChange={(event) => {setProps({...props, "name": event.target.value})}              } ></input><br />
				
				<select defaultValue={product.location}    onChange={(e) =>     {setProps({...props, "location": e.target.value})}}>
					<option value="">No Page</option>
					<option value="items">Items</option>
					<option value="honey">Honey</option>
				</select>                
				
				<input defaultValue={product.stock}        onChange={(event) => {setProps({...props, "stock": parseInt(event.target.value)})}   } ></input><br />
				<input defaultValue={product.description}  onChange={(event) => {setProps({...props, "description": event.target.value})}       } ></input><br />
				<input defaultValue={product.position} type="number" onChange={(event) => {setProps({...props, "position": parseInt(event.target.value)})}} ></input><br />
				
				<input type="file" style={{width: "100%"}} onChange={(event) => { 	
														if(!event.target.files) {return;}
														uploadImage(`products/${props.id[0]}00/${props.id.slice(1)}`, event.target.files[0])}   } ></input><br />
			</td>
            <td className="pricesDivider">
                <b>Prices</b> <br />
				{subproductHTML}
				<button key={"addbtn"} onClick={(e) => { setRelations({...relations, "0": {"price": 0}}) }}>+</button>
            </td>
        </tr>
        </tbody>

        </table>

    </div>
	)
}


function Render() {

    const [productHTML, setProductHTML] = useState<JSX.Element[]>();

    useEffect(() => {
        const init = async() => {
            let renderedProducts: JSX.Element[] = [];
            let products = await ProductService.setProductList();

            products.map((p, i) => {
				if(p.id !== "0") {
					renderedProducts[i] = <ProductElement key={p.id} product={p} products={products}/>
				}
			})

            setProductHTML(renderedProducts);
        }
        init();
    }, []);

    return (
        <div>
            {productHTML}
        </div>
    );
}

export default Render;