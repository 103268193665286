import {Database} from "./ajax.js";
import {Relation} from "../models/Relation";
import {Product} from "../models/Product";
import { PurchasedItem } from "../models/PurchasedItem.js";
import { AxiosError } from "axios";
import { ToastsStore } from "react-toasts";
// import productsJson from "../resources/products.json";

export let productList: Product[] = [];


export const ProductService = {

    setProductList: async () => {
        let response: JSON = ((await Database.getProducts()) as unknown as JSON)["response" as keyof JSON] as unknown as JSON;
        let productsJson: JSON[] = response["products" as keyof JSON] as unknown as JSON[];
        
        productsJson.map((p, idx) => productList[idx] = p as Object as Product);
        return productList;
    },

    getProductsByLocation: (location: string) => {
        let products: Product[] = [];
        productList.forEach(element => {
            if (element.location === location) {
                products.push(element);
            }
        });
        return products;
    },

    getProduct: (id: string) => {
        for (let element in productList) {
            if (productList[element].id === id) {
                return productList[element];
            }
        }
        return ({"relations": {}} as Product);
    },

    getRelation: (id: string, subProductID: string) => {
        let product = ProductService.getProduct(id);
        if(product.relations[subProductID as keyof typeof product.relations] as Object as Relation === undefined) {
            return {"price": 0.00, "imageURL": "about:blank"} as Object as Relation;
        }
        return product.relations[subProductID as keyof typeof product.relations] as Object as Relation;
    }

}

export const CartService = {
    stringifyPurchase: async (item: PurchasedItem) => {
        productList = await ProductService.setProductList();
    
        return `${item.amount}x ${item.subProductName === "" ? "" : `${item.subProductName} of`} ${item.productName} ($${item.price * item.amount})`
    },

    calculateTotal: (cart: PurchasedItem[]) => {
        let sum: number = 0;
        for(let i in cart) {
            sum += cart[i].amount * cart[i].price;
        }
        return sum;
    }
}

export function toastAxiosError(e: AxiosError) {
	let response = (e as AxiosError).response?.data as JSON;
	ToastsStore.error(`${JSON.stringify(response["response" as keyof JSON])}`);
}

export function createRelation(relation: Object) {
    return ((relation as Relation));
}