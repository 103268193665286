import axios from "axios";
import Cookies from 'universal-cookie';

export const Database = {
    getProducts: async (location) => {
        const queryString = location == null ? "" : `?location=${location}`
        const url = `${process.env.REACT_APP_BACKEND_URL}/db/getProducts${queryString}`;
        const response = await axios.get(url);
        return response.data;
    },
	setProduct: async (id, newProduct) => {
		const url = `${process.env.REACT_APP_BACKEND_URL}/db/setProduct`
		const body = {
			"id": id,
			"newProduct": newProduct
		}
		const config = { withCredentials: true }
		const response = await axios.patch(url, body, config);
		return response.data;
	},
    deleteProduct: async(id) => {
		const url = `${process.env.REACT_APP_BACKEND_URL}/db/deleteProduct`
		const body = {
			"id": id
		}
		const config = { withCredentials: true }
		const response = await axios.post(url, body, config);
		return response.data;
    }
}

export const Orders = {
    getAll: async () => {
        const url = `${process.env.REACT_APP_BACKEND_URL}/orders/get/all`;
        const config = { withCredentials: true }
        const response = await axios.get(url, config);
        return response.data;
    },
    complete: async (orderID, state) => {
        const url = `${process.env.REACT_APP_BACKEND_URL}/orders/action/complete`;
        const config = { withCredentials: true }
        const body = {
            "orderID": orderID,
            "value": !!state
        }
        const response = await axios.patch(url, body, config);
        return response;
    },

    archive: async (orderID) => {
        const url = `${process.env.REACT_APP_BACKEND_URL}/orders/action/archive`;
        const config = { withCredentials: true }
        const body = {
            "orderID": orderID
        }
        const response = await axios.patch(url, body, config);
        return response;
    },
	pay: async (orderID, paymentStatus) => {
        const url = `${process.env.REACT_APP_BACKEND_URL}/orders/action/pay`;
        const config = { withCredentials: true }
        const body = {
            "orderID": orderID,
			"value": paymentStatus
        }
        const response = await axios.patch(url, body, config);
        return response;
    }
}

export const Email = {
    sendEmail: async (orderID) => {
        const url = `${process.env.REACT_APP_BACKEND_URL}/email/completionEmail`;
        const config = { withCredentials: true }
        const body = {
            "orderID": orderID
        }
        const response = await axios.post(url, body, config);
        return response;
    }
}

export const Auth = {
    getUser: async () => {
        const url = `${process.env.REACT_APP_BACKEND_URL}/auth/getUser`
        const config = { withCredentials: true }
        const response = await axios.get(url, config);
        return response.data;
    },
	getUsers: async () => {
        const url = `${process.env.REACT_APP_BACKEND_URL}/auth/getUsers`
        const config = { withCredentials: true }
        const response = await axios.get(url, config);
        return response.data;
    },
	setUser: async (id, userData) => {
        const url = `${process.env.REACT_APP_BACKEND_URL}/auth/setUser`
        const config = { withCredentials: true }
		const body = {
			"id": id,
			"userData": userData
		}
        const response = await axios.post(url, body, config);
        return response.data;
    }
}

export const CDN = {
	uploadImage: async (file, path) => {
		const formdata = new FormData();
		const url = `${process.env.REACT_APP_CDN_URL}/upload/${path}`
		formdata.append("image", file);
		const config = { 
			headers: { 'Content-Type': 'multipart/form-data' },
			withCredentials: true,
		}
		let response = axios.post(url, formdata, config);
		return response.data;
	}
}
