import "./Users.css"
import "./Products.css"
import { useEffect, useState } from "react";
import { ToastsStore } from "react-toasts";
import jwtDecode from "jwt-decode";
import Cookies from "universal-cookie";
import { confirmAlert } from "react-confirm-alert";

import { Auth } from "../lib/ajax";

import { User } from "../models/User";
import { DecodedJWT } from "../models/DecodedJWT";
import { AxiosError } from "axios";
import { toastAxiosError } from "../lib/utils";

function RenderUser({user}: {user: User}) {

	const [def_props, setDef_props] = useState(user);
	const [props, setProps] = useState(user);

	const [isChanged, setIsChanged] = useState(false);

	useEffect(() => {
		setIsChanged(true);
		if(JSON.stringify(props) === JSON.stringify(def_props)) {
			setIsChanged(false);
		}
	}, [props]);

	function saveUser() {

		const save = async() => {
			try {
				await Auth.setUser(props.id, {"permissions": props.permissions, "receiveEmailsOnOrder": props.receiveEmailsOnOrder});
				setDef_props(props);
				setIsChanged(false);
				ToastsStore.success("Updated User");
			} catch (e) {
				toastAxiosError(e as AxiosError);
			}
		}

		const cookies = new Cookies;
		if ( (jwtDecode(cookies.get("auth")) as DecodedJWT).sub === props.id) {
			confirmAlert({
				"title": "Confirm Updating User",
				"message": "You are updating the permissions of the account you are logged in from. Are you sure?",
				"buttons": [
					{
						"label": "Yes",
						onClick: () => save()
					},
					{
						"label": "No",
						onClick: () => ToastsStore.error("User not updated")
					}
				]
			})
		} else {
			save();
		}

	}

	return (
	<div className="userContainer">

		<button className={`top-right btn ${isChanged ? "active" : "inactive"}`} disabled={!isChanged} onClick={() => {saveUser()}}>Save</button>

		<table>
		<tbody>
			<tr>
				<td>
					Name: <br />
					ID: <br />
					Email: <br />
					Permissions: <br />
					Receive Order Emails: <br />
				</td>
				<td>
					{user.name} <br />
					{user.id} <br />
					{user.email} <br />
					<select defaultValue={user.permissions} onChange={(e) => {setProps({...props, "permissions": e.target.value})}}>
						<option value="user">User</option>
						<option value="admin">Admin</option>
					</select> <br />
					<input type="checkbox" defaultChecked={props.receiveEmailsOnOrder} onChange={(e) => {setProps({...props, "receiveEmailsOnOrder": e.target.checked})}}/>
				</td>
			</tr>	
		</tbody>
		</table>
	</div>);
}

function Render() {

	const [html, sethtml] = useState<JSX.Element[]>([]);

	useEffect(() => {
		const init = async() => {
			let Users = (await Auth.getUsers())["response"];

			console.log(Users);
			let newhtml = []
			for(let i in Users) {
				newhtml.push(<RenderUser key={i} user={Users[i] as User} />);
			}
			sethtml(newhtml);
		}
		init();
	}, [])

	return <div style={{width: "100%"}}>
		{html}
	</div>
}

export default Render;