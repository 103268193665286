import "./Products.css"
import { useEffect, useState } from "react";
import { ToastsStore } from "react-toasts";
import { AxiosError } from "axios";

import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

import { ProductService, toastAxiosError } from "../lib/utils";
import { CDN, Database } from "../lib/ajax.js"

import { Product } from "../models/Product";

function ProductDropdown({products, defaultValue, onChange}: {products: Product[], defaultValue: string, onChange: (product: Product) => void}) {
    let dropdownHTML: JSX.Element[] = [];
    products.map((p, i) => (dropdownHTML[i] = <option key={p.id} value={p.id}>{p.name}</option>));
    return (
		<select onChange={(event) => onChange(ProductService.getProduct(event.target.value))} defaultValue={defaultValue}>
			{dropdownHTML}
		</select>
    );
}

function SubproductSelector(
	{defaultProduct, defaultPrice, products, onChange, onDelete}: 
	{defaultProduct: string, defaultPrice: number, products: Product[], 
		onChange: (oldID: string, newID: string, price: number) => void, onDelete: (ID: string) => void}) {
	
	const [ID, setID] = useState<string>(defaultProduct);
	const [price, setPrice] = useState<number>(defaultPrice);
	
	return (
	<div className="subproductSelectorDiv">
		<ProductDropdown products={products} defaultValue={defaultProduct} onChange={(p) => {
			onChange(ID, p.id, price);
			setID(p.id);
		}} />

		$<input className="priceInputBox" type="number" size={3} defaultValue={defaultPrice} onChange={(e) => {
			onChange(ID, ID, parseFloat(e.target.value));
			setPrice(parseFloat(e.target.value));
		}} />

		<button onClick={(e) => {onDelete(ID)}}>🗑</button>
		
	</div>);
}


function ProductElement({products}: {products: Product[]}) {
	
	const [props, setProps] = useState({
		"id": "",
		"name": "",
		"location": "",
		"stock": 0,
		"position": 0,
		"description": "",
	} as Product);

	const [relations, setRelations] = useState(JSON.parse("{}"));
	
	const [subproductHTML, setSubproductHTML] = useState<JSX.Element[]>([]);
	

	useEffect(() => {
		let html: JSX.Element[] = [];

		for(let i in relations) {
			html.push(
				<div key={i}>
					<SubproductSelector defaultProduct={i} defaultPrice={relations[i].price} products={products}
					onChange={(op, np, price) => {
						let newSubProducts = structuredClone(relations);
						newSubProducts[np] = {...newSubProducts[op], "price": price}; //carry over everything and update price
						if(op != np) { //if the subproduct changed, delete the old one
							delete newSubProducts[op];
						}
						setRelations(newSubProducts)
					}} 
					onDelete={(id) => {
						let newSubProducts = structuredClone(relations);
						delete newSubProducts[id];
						setRelations(newSubProducts);
					}}  />
					<br />
				</div>)
		}
		setSubproductHTML(html);
	}, [relations]);

	function saveProduct() {
		const save = async() => {
			let productID = "000";
			for(let i in products) {
				if(products[i].id[0] == props.id) {
					productID = products[i].id;
				}
			}
			productID = (parseInt(productID)+1).toString();

			try {
				await Database.setProduct(productID, {
					"name": props.name,
					"description": props.description,
					"location": props.location,
					"stock": props.stock,
					"position": props.position,
					"relations": relations
				});
				ToastsStore.success("Created Product");
			} catch (e) {
				toastAxiosError(e as AxiosError);
			}
		}

		if(props.id === "") {
			ToastsStore.error("Please select an ID category");
			return;
		}

		confirmAlert({
			"title": "Confirm Product Creation",
			"message": "Are you sure you want to create the product? This cannot be undone",
			"buttons": [
				{
					"label": "Yes",
					onClick: () => save()
				},
				{
					"label": "No",
					onClick: () => ToastsStore.error("Product Creation Cancelled")
				}
			]
		})
	}

	return (
		<div className="productContainer">
        
        <b>Create Product</b>
        

        <br />
        <table>
        <tbody>
        <tr>
            <td>
                <b>Properties</b> <br />
                ID:       <br />
                Name:     <br />
                Location: <br />
                Stock:    <br />
                Desc:     <br />
				Position: <br />
            </td>
            <td className="inputDivider">
                <br />
                <select onChange={(e) => {setProps({...props, "id": e.target.value})}} defaultValue={""}>
					<option value="">Select</option>
					<option value="1">Items (1XX)</option>
					<option value="2">Liquids (2XX)</option>
					<option value="3">Containers (3XX)</option>
				</select>
                <input onChange={(event) => {setProps({...props, "name": event.target.value})}             } ></input><br />
				<select onChange={(e) =>    {setProps({...props, "location": e.target.value})}} defaultValue={""}>
					<option value="">No Page</option>
					<option value="items">Items</option>
					<option value="honey">Honey</option>
				</select>
                <input onChange={(event) => {setProps({...props, "stock": parseInt(event.target.value)})}  } ></input><br />
				<input onChange={(event) => {setProps({...props, "description": event.target.value})}      } ></input><br />
				<input type="number" onChange={(event) => {setProps({...props, "position": parseInt(event.target.value)})}      } ></input><br />
			</td>
            <td className="pricesDivider">
                <b>Prices</b> <br />
				{subproductHTML}
				<button key={"addbtn"} onClick={(e) => { setRelations({...relations, "0": {"price": 0}}) }}>+</button>
            </td>
        </tr>
        </tbody>

        </table>
		<button className={`btn active`} onClick={async() => {saveProduct()}} >Create Product</button>

    </div>
	)
}


function Render() {
	
    const [productHTML, setProductHTML] = useState<JSX.Element>(<a></a>);
	
    useEffect(() => {
		const init = async() => {
			let html: JSX.Element;
            let products = await ProductService.setProductList();
			
			html = <ProductElement products={products}/>
			
            setProductHTML(html);
        }
        init();
    }, []);

    return (
        <div>
            {productHTML}
        </div>
    );
}

export default Render;