import "./Orders.css"
import { useEffect, useState } from 'react';
import { ToastsStore } from 'react-toasts'
import { confirmAlert } from "react-confirm-alert";

import { Orders, Email } from '../lib/ajax'
import { ProductService, CartService } from "../lib/utils";

import { PurchasedItem } from '../models/PurchasedItem';
import { PlacedOrder } from '../models/PlacedOrder';

export function createOrders(orders: PlacedOrder[], location: string) {
    let components: JSX.Element[] = [];
    
    orders.forEach(element => {
        components.push(<CreateOrder order={element} location={location} key={`${element.id}`}/> );
    });
    
    return components;
}


async function setComplete(order: PlacedOrder) {
    let res = await Orders.complete(order.id, !order.isComplete);
    if(res.status == 200) {
        ToastsStore.success(`Marked as ${order.isComplete ? "Incomplete":"Complete"}`);
    } else {
        ToastsStore.error(`Error: ${res.status}`);
    }
    document.getElementById("refreshbtn")?.dispatchEvent(new MouseEvent("click", {view: window, bubbles: true, cancelable: true, buttons: 1}));
}

async function sendEmail(order: PlacedOrder) {
    let res = await Email.sendEmail(order.id);
    if(res.status == 200) {
        ToastsStore.success(`Sent Email`);
    } else {
        ToastsStore.error(`Error: ${res.status}`);
    }
    document.getElementById("refreshbtn")?.dispatchEvent(new MouseEvent("click", {view: window, bubbles: true, cancelable: true, buttons: 1}));
}

async function pay(order: PlacedOrder) {

	const pay = async(order: PlacedOrder) => {
		let res = await Orders.pay(order.id, !order.paid);
		if(res.status == 200) {
			order.paid = !order.paid;
			ToastsStore.success(`Updated the payment status`);
		} else {
			ToastsStore.error(`Error: ${res.status}`);
		}
		document.getElementById("refreshbtn")?.dispatchEvent(new MouseEvent("click", {view: window, bubbles: true, cancelable: true, buttons: 1}));
	}

	confirmAlert({
		"title": "Are you sure?",
		"message": "Are you sure you want to update the payment status for this order?",
		"buttons": [
			{
				"label": "Yes",
				onClick: () => pay(order)
			},
			{
				"label": "No",
				onClick: () => ToastsStore.error("Not updated")
			}
		]
	})

}

async function archive(order: PlacedOrder) {
	const archive = async() => {
		let res = await Orders.archive(order.id);
		if(res.status == 200) {
			ToastsStore.success(`Archived`);
		} else {
			ToastsStore.error(`Error: ${res.status}`);
		}
		document.getElementById("refreshbtn")?.dispatchEvent(new MouseEvent("click", {view: window, bubbles: true, cancelable: true, buttons: 1}));
	}

	confirmAlert({
		"title": "Are you sure?",
		"message": "Are you sure you would like to archive this order? This cannot be undone",
		"buttons": [
			{
				"label": "Yes",
				onClick: () => archive()
			},
			{
				"label": "No",
				onClick: () => ToastsStore.error("Order not archived")
			}
		]
	});
}

export function CreateOrder({order, location}: {order: PlacedOrder, location: string}) {

    let purchasedItems: PurchasedItem[] = [];
    for(let i in order.purchases as Object[]) {
        purchasedItems[i] = (order.purchases as Object[])[i] as PurchasedItem;
    }
    let subtotal: number = CartService.calculateTotal(purchasedItems);
    let total = subtotal + (subtotal * ((process.env.REACT_APP_TAX as any) as number));

    const [purchases, setPurchases] = useState<JSX.Element[]>();
    const [actions, setActions] = useState<JSX.Element[]>();

    useEffect(() => {
        const init = async() => {
            let purchasesHTML: JSX.Element[] = [];
    
            for(let i in purchasedItems) {
                purchasesHTML.push(
                <div key={`${JSON.stringify(purchasedItems[i])}`}>
                    {await CartService.stringifyPurchase(purchasedItems[i])}
                <br /></div>);
            }
            setPurchases(purchasesHTML);

            let actionsHTML: JSX.Element[] = [];

            switch(location) {
                case "active":
                    actionsHTML = [
                        <a key="0" onClick={() => setComplete(order)}>Set as Complete</a>,
                        <a key="1" onClick={() => pay(order)}>{order.paid ? "Order Is Not Paid" : "Order Is Paid"}</a>
                    ]
                    break;
                case "complete":
                    actionsHTML = [
                        <a key="0" onClick={() => setComplete(order)}>Set as Incomplete</a>,
                        <a key="1" onClick={() => pay(order)}>{order.paid ? "Order Is Not Paid" : "Order Is Paid"}</a>,
                        <a key="2" onClick={() => sendEmail(order)}>{order.emailSent ? "Email Sent" : "Send Email"}</a>,
                        <a key="3" onClick={() => archive(order)}>Archive</a>
                    ]
                    break;
                case "archive":
                    break;
            }
            setActions(actionsHTML);
        }
        init();
    }, []); //Might need a [] here for preventing unneeded rerenders (yes i do).


    return (
		<div>

			<table>
			<tbody>
			<div className="dropdown">
				<button key={"a0"}  className="dropbtn">...</button>
				<div key={"a1"} className="dropdown-content">
					{actions}
				</div>
			</div>
			<tr>
				<td>
					<b>Customer</b> <br />
					Order:  <br />
					Name:   <br />
					Email:  <br />
					Address:<br />
					Phone:  <br />
					Date:   <br />
					Cost:   <br />
					Paid:   <br />
				</td>
				<td>
					<br />
					#<b>{order.id}</b><br />
					<b>{order.name}</b><br />
					<b>{order.email}</b><br />
					<b>{order.address}</b><br />
					<b>{order.phoneNumber}</b><br />
					<b>{new Intl.DateTimeFormat('en-US', { year: 'numeric', month: '2-digit', day: '2-digit'}).format(order.date)}</b><br />
					$<b>{total.toFixed(2)}</b><br />
					<b>{order.paid ? "true":"false"}</b><br />
				</td>
				<td>
					<b>Purchases</b> <br />
					{purchases}
				</td>
			</tr>
			</tbody>
			</table>

			<br />
		</div>

    )

}

function OrderPage() {

    const [activeOrdersHTML, setActiveOrdersHTML] = useState<JSX.Element[]>();
    const [completeOrdersHTML, setCompleteOrdersHTML] = useState<JSX.Element[]>();

    const [refresh, setRefresh] = useState(false);

    useEffect(() => {
        const init = async() => {
            await ProductService.setProductList();
            let response;
            try {
                response = await Orders.getAll();
            } catch {
                ToastsStore.error("403: Forbidden");
            }

            // create the lists of orders
            let allActiveOrders = response["response" as keyof JSON]["active" as keyof JSON];
            let completeOrders: PlacedOrder[] = [];
            let activeOrders: PlacedOrder[] = [];

            for(let i in allActiveOrders) {
                allActiveOrders[i].id = i;
                if(allActiveOrders[i].isComplete) {
                    completeOrders.push(allActiveOrders[i] as PlacedOrder);
                    continue;
                }
                activeOrders.push(allActiveOrders[i] as PlacedOrder);
            }
            //set the HTML of the orders
            setActiveOrdersHTML(createOrders(activeOrders, "active"));
            setCompleteOrdersHTML(createOrders(completeOrders, "complete"));
        }
        init();
    }, [refresh]);
    return (
        <div>
            <br />
            <button id="refreshbtn" className="refreshbtn" onClick={() => {setRefresh(!refresh)}}>Refresh</button>
            <div className="orderDiv">
                <h2 className="orderElementDiv" >Active Orders</h2> <br />
                <div className="orderElementDiv"> {activeOrdersHTML} </div>
                <h2 className="orderElementDiv" >Complete Orders</h2> <br />
                <div className="orderElementDiv"> {completeOrdersHTML} </div>
            </div>
        </div>
    );
}

export default OrderPage;